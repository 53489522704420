/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'); */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "fonts.css";
@import "./generic-slide.css";
@import "./header.css";
@import "./keyframes.css";
@import "./forms.css";
@import "./article-modules.css";

@layer base {
  * {
    @apply box-border outline-transparent;
  }
  html {
    @apply text-[14px] 2xs:text-[16px] lg:text-[14px]  2xl:text-[16px] 3xl:text-[18px];
  }
  @media (max-width: 319px) {
    html {
      @apply text-[12px];
    }
  }
  html,
  body,
  input {
    @apply selection:bg-primary selection:text-[#fff] !important;
  }
  body {
    @apply text-base text-dark;
  }
  body[dir="ltr"] {
    @apply font-body;
  }
  body[dir="rtl"] {
    @apply font-cairo;
  }

  main {
    @apply relative z-0 min-h-[5rem];
  }
  div {
    scroll-margin-top: 6rem;
  }
  main p {
    @apply mb-sm text-darkGrey last:mb-0;
  }

  main ul ul,
  main ol ol {
    @apply pl-[1rem];
  }

  a {
    @apply cursor-pointer;
  }

  iframe {
    @apply max-w-full;
  }

  .modal a,
  main a {
    @apply text-secondary transition duration-200 ease-in-out hover:text-secondaryHover;
  }

  img {
    @apply inline-block max-w-full align-middle;
  }

  /* main section{
        @apply md:mb-md lg:mb-2xl;
    } */
  input[type="checkbox"]:focus,
  input[type="checkbox"] {
    @apply outline-0 ring-transparent ring-offset-0;
  }
  input {
    @apply outline-0  focus:outline-0 focus:ring-0  !important;
  }

  button {
    @apply outline-0;
  }

  video {
    -webkit-mask-image: -webkit-radial-gradient(white, #000);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

@layer components {
  /* greneral-components */
  .container {
    @apply my-0 mx-auto flex w-full max-w-[68.88rem] flex-col py-0 px-md;
  }

  .container-large {
    @apply max-w-[80rem];
  }

  .mobile {
    @apply block lg:hidden;
  }
  .desktop {
    @apply hidden lg:block;
  }
  .center {
    @apply text-center;
  }
}

.text-container img {
  image-rendering: -webkit-optimize-contrast;
}

.next-image-wrapper span,
.next-image-wrapper img {
  @apply align-middle;
}

.swiper-thumbs .swiper-slide-duplicate {
  @apply lg:hidden;
}
.swiper .btn,
.swiper a {
  @apply relative z-10;
}

.heroAnimation {
  animation: bounce-in-top 12s alternate infinite;
  transform: scale(0.75, 0.75);
}

.progressBarHomepageAnimation {
  animation: progressBarHomepage 6s linear;
}

/* Modals  */
.licence-modal p,
.licence-modal button,
.licence-modal li {
  @apply text-base md:text-sm 2xl:text-base;
}
.licence-modal .licence-modal-accordion > div > div > div:first-child p {
  @apply text-h4;
}
.licence-modal .licence-modal-accordion > div > div > div:nth-child(2) > div {
  @apply p-0;
}

.mantine-Paper-root.mantine-Modal-modal {
  padding: 0 !important;
}
.mantine-Paper-root.mantine-Modal-modal .mantine-Modal-header {
  margin: 0;
}

.highcharts-credits {
  @apply hidden !important;
}

.text-outer-border-fixed {
  text-shadow: 3px 3px 0px #fff, 4px 4px 0px #000, 4px 2px 0px #000, 2px 4px 0px #000;
}
.text-outer-border {
  text-shadow: 0.05859375em 0.05859375em 0px #fff, 0.068359375em 0.068359375em 0px #000,
    0.068359375em 0.048828125em 0px #000, 0.048828125em 0.068359375em 0px #000;
}

/* temporary fix to hide ze chat on mobiles */
iframe#launcher,
iframe[title="Button to launch messaging window"] {
  @apply hidden lg:block !important;
}

.redFilter {
  filter: brightness(0) saturate(100%) invert(61%) sepia(98%) saturate(5154%) hue-rotate(335deg) brightness(105%)
    contrast(111%);
}
.greenFilter {
  filter: brightness(0) saturate(100%) invert(59%) sepia(86%) saturate(896%) hue-rotate(53deg) brightness(97%)
    contrast(86%);
}

/* Typography styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply m-0 mb-lg font-normal last:mb-0 ltr:font-gilroy rtl:font-dubai;
}

h1,
h2 {
  @apply text-[1.5rem] leading-[1.1em] md:text-h2;
}
h3 {
  @apply text-[1.4rem] leading-[1.2em] md:text-h3;
}
h4 {
  @apply text-[1.3rem] leading-[1.33em] md:text-h4;
}
h5 {
  @apply text-[1.15rem] leading-[1.5em] md:text-h5;
}
h6 {
  @apply text-[1rem] leading-[1.5em] md:text-base;
}

html[lang="vi"] body h1,
html[lang="vi"] body h2,
html[lang="vi"] body h3,
html[lang="vi"] body h4,
html[lang="vi"] body h5,
html[lang="vi"] body h6 {
  @apply font-opensans !important;
}

main .rich-text p,
main .rich-text ul,
main .rich-text ol,
main .rich-text h1,
main .rich-text h2,
main .rich-text h3,
main .rich-text h4,
main .rich-text h5,
main .rich-text h6 {
  margin-bottom: 0;
}

.rich-text figure.image span,
.rich-text figure img {
  @apply inline-block align-middle;
}

.rich-text .text-small {
  font-size: 0.9em;
}

main .rich-text h1 .text-huge,
main .rich-text h2 .text-huge {
  @apply text-[2.77rem] font-black leading-[1.1]  lg:leading-[1.1] ltr:lg:text-[3.777rem] rtl:lg:text-[4rem]  2xl:leading-[1.1] ltr:2xl:text-[4.2rem] rtl:2xl:text-[4.5rem] ltr:3xl:text-[4.777rem] rtl:3xl:text-[5rem];
}
main .rich-text h2 .text-big {
  @apply text-[2.375rem] font-black lg:text-[3.444rem] lg:leading-[1.1] 2xl:text-[4.444rem] 2xl:leading-[1.1];
}
main .rich-text h2 .text-small {
  @apply text-[2rem] font-black lg:text-[3.333rem] lg:leading-[1.1] 2xl:text-[4.333rem] 2xl:leading-[1.1];
}
html[lang="de"] main .rich-text h1 .text-tiny,
main .rich-text h2 .text-tiny {
  @apply font-black  lg:text-[3.222rem] lg:leading-[1.1] 2xl:text-[4.222rem] 2xl:leading-[1.1];
}
main .rich-text h3 .text-huge {
  @apply font-black lg:text-[3rem] lg:leading-[1.1] 2xl:text-[4rem] 2xl:leading-[1.1];
}
main .rich-text h3 .text-big {
  @apply font-bold lg:text-[2.777rem] lg:font-black lg:leading-[1.1] 2xl:text-[3.777rem] 2xl:leading-[1.1];
}

@media (max-height: 700px) and (min-width: 1024px) {
  main .rich-text h1 .text-huge,
  main .rich-text h2 .text-huge {
    @apply ltr:text-[2.777rem] rtl:text-[3rem];
  }
  main .rich-text h2 .text-big {
    @apply text-[2.444rem];
  }
  main .rich-text h2 .text-small {
    @apply text-[2.333rem];
  }
  main .rich-text h2 .text-tiny {
    @apply text-[2.222rem];
  }
  main .rich-text h3 .text-huge {
    @apply text-[2rem];
  }
  main .rich-text h3 .text-big {
    @apply text-[1.777rem];
  }
}
@media (max-width: 767px) {
  main .rich-text h1 .text-huge:lang(ro),
  main .rich-text h2 .text-huge:lang(ro) {
    font-size: 2.4rem;
  }
}

@media (max-height: 700px) and (max-width: 767px) {
  main .rich-text h1 .text-huge,
  main .rich-text h2 .text-huge {
    @apply ltr:text-[2.4rem] rtl:text-[2.4rem];
  }
  main .rich-text h2 .text-big {
    @apply text-[2rem];
  }
  main .rich-text h2 .text-small {
    @apply text-[1.8rem];
  }
}

/* End of typography styles */

#nuntius-faq-iframe figure {
  @apply mx-auto rounded-lg md:max-w-[90%] lg:max-w-[85%];
}

#nuntius-faq-iframe iframe {
  @apply rounded-lg;
}

#nuntius-faq-iframe p {
  @apply md:mx-auto md:max-w-[75%];
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  #zachKingSponsorship {
    margin-bottom: -18rem;
  }
  #zachKingSponsorship > div {
    transform: none !important;
  }
}

.seo-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* for older browsers */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.video-no-border {
  -webkit-mask-image: -webkit-radial-gradient(white, #000);
  -webkit-backface-visibility: hidden;
}

.trade-view-steps:last-child .steps-arrow {
  @apply hidden;
}

main .toc-header.toc-header {
  @apply -mt-[11rem] pt-[11rem] !important;
}

.loader {
  width: 31px;
  padding: 5px;
  background: #0995dd;

  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load 1s linear infinite;
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
main .rich-text ul.todo-list li.todo-list:before,
main .rich-text .todo-list li.todo-list:before,
main .rich-text ul.todo-list > li:before,
main .rich-text li.todo-list:before {
  display: none;
}

.hide-mobile-text {
  @apply hidden lg:block;
}