/* Fonts */

/* Gilroy Light */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/light/gilroy-light-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/light/gilroy-light-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/light/gilroy-light-webfont.woff") format("woff"),
    url("./fonts/gilroy/light/gilroy-light-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/light/gilroy-light-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
/* Gilroy Regular */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/regular/gilroy-regular-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/regular/gilroy-regular-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/regular/gilroy-regular-webfont.woff") format("woff"),
    url("./fonts/gilroy/regular/gilroy-regular-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/regular/gilroy-regular-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 400;
  font-display: swap;
}

/* Gilroy SemiBold */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/semibold/gilroy-semibold-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/semibold/gilroy-semibold-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/semibold/gilroy-semibold-webfont.woff") format("font-woff"),
    url("./fonts/gilroy/semibold/gilroy-semibold-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/semibold/gilroy-semibold-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* Gilroy Bold */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/bold/gilroy-bold-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/bold/gilroy-bold-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/bold/gilroy-bold-webfont.woff") format("font-woff"),
    url("./fonts/gilroy/bold/gilroy-bold-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/bold/gilroy-bold-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Gilroy ExtraBold */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/extrabold/gilroy-extrabold-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/extrabold/gilroy-extrabold-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/extrabold/gilroy-extrabold-webfont.woff") format("woff"),
    url("./fonts/gilroy/extrabold/gilroy-extrabold-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/extrabold/gilroy-extrabold-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Gilroy Black */
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/gilroy/black/gilroy-black-webfont.eot"); /* IE9 Compat Modes */
  src: url("./fonts/gilroy/black/gilroy-black-webfont.woff2") format("woff2"),
    url("./fonts/gilroy/black/gilroy-black-webfont.woff") format("woff"),
    url("./fonts/gilroy/black/gilroy-black-webfont.ttf") format("truetype"),
    url("./fonts/gilroy/black/gilroy-black-webfont.svg") format("svg"); /* Legacy iOS */
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* source-sans-pro-300 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-300italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-300italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local(""),
    url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-900italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/source-sans-pro/source-sans-pro-v21-vietnamese_latin-ext_latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-800.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local(""), url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-800italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/open-sans/open-sans-v34-vietnamese_latin-ext_latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/dubai/Dubai-Light.ttf") format("ttf"), url("./fonts/dubai/Dubai-Light.woff") format("woff");
}
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/dubai/Dubai-Regular.ttf") format("ttf"), url("./fonts/dubai/Dubai-Regular.woff") format("woff");
}
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/dubai/Dubai-Medium.ttf") format("ttf"), url("./fonts/dubai/Dubai-Medium.woff") format("woff");
}
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/dubai/Dubai-Bold.ttf") format("ttf"), url("./fonts/dubai/Dubai-Bold.woff") format("woff");
}

/* cairo-300 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-300.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-300.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-regular - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-regular.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-500 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-500.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-500.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-600 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-600.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-600.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-700 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-700.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-700.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-800 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-800.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-800.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-800.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-800.svg#Cairo") format("svg"); /* Legacy iOS */
}
/* cairo-900 - arabic */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./fonts/cairo/cairo-v22-arabic-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/cairo/cairo-v22-arabic-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/cairo/cairo-v22-arabic-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-900.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/cairo/cairo-v22-arabic-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/cairo/cairo-v22-arabic-900.svg#Cairo") format("svg"); /* Legacy iOS */
}
