form label {
  @apply text-grey;
}

form [type="text"],
form [type="url"],
form [type="email"],
form [type="password"],
form [type="number"],
form [type="date"],
form [type="datetime-local"],
form [type="month"],
form [type="search"],
/* form [type="tel"], */
form [type="time"],
form [type="week"],
form [multiple],
form textarea,
form select {
  @apply w-full rounded-md border border-borderGrey px-md py-[0.5rem] text-base text-grey 
  placeholder:text-inherit placeholder:opacity-100
  focus:border-secondary focus:text-dark;
}
form select {
  @apply pr-[2.25rem];
}
form input[type="checkbox"] {
  @apply ltr:mr-md rtl:ml-md;
}

form button[type="submit"] {
  @apply inline-flex   cursor-pointer items-center rounded-md  bg-secondary  px-[1.7em] py-[0.7em] text-center   align-middle font-medium text-white shadow-sm transition-all duration-300 ease-in-out hover:bg-secondaryHover;
}
form button[type="submit"][disabled] {
  @apply cursor-wait bg-secondaryHover opacity-50;
}
form .button-wrapper {
  @apply flex justify-center;
}
.phone-field-wrapper {
  @apply w-full rounded-md border border-borderGrey px-md py-[0.02rem];
}
.phone-field-wrapper input {
  @apply border-none text-base text-grey
    placeholder:text-inherit placeholder:opacity-100
    focus:border-secondary focus:text-dark;
}

.PhoneInputCountrySelectArrow {
  @apply rtl:mr-sm;
}
