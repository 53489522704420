.cssVerticalSlider {
  display: inline-grid;
  justify-content: center;
  overflow: hidden;
  @apply h-[1.17em] transition-all duration-300 rtl:h-[1.4em];
}

.cssVerticalSliderInner {
  position: relative;
  @apply inline-flex flex-col items-start  align-middle  transition-all duration-300;
}

h1 small .cssVerticalSliderInner {
  @apply relative;
}

.cssVerticalSliderInner > div {
  padding-bottom: 5px;
  box-sizing: border-box;
  disaplay: inline-block;
  vertical-align: middle;
  @apply [&:nth-child(3n+1)]:text-[#60ABBB] [&:nth-child(3n+2)]:text-[#22A116] [&:nth-child(3n+3)]:text-[#2744B8];
  position: relative;
}
