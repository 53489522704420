.link {
    @apply  transition-all duration-300 ease-in-out
}

.link-arrow-right {
    @apply after:w-0 after:h-0 after:inline-block after:align-middle after:border-t-[0.28em] after:border-t-transparent after:border-b-[0.28em] after:border-b-transparent ltr:after:border-l-[0.35em] rtl:after:border-r-[0.35em] ltr:after:border-l-current rtl:after:border-r-current after:relative ltr:after:ml-[1em] rtl:after:mr-[1em] items-center  ltr:text-left rtl:text-right
}

.link-arrow-left {
    @apply before:w-0 before:h-0 before:inline-block before:align-middle before:border-t-[0.28em] before:border-t-transparent before:border-b-[0.28em] before:border-b-transparent ltr:before:border-l-[0.35em] rtl:before:border-r-[0.35em] ltr:before:border-l-current rtl:before:border-r-current before:relative ltr:before:mr-[1em] rtl:before:ml-[1em] items-center before:top-[-0.05em] ltr:text-left rtl:text-right
}

.blue {
    @apply text-secondary hover:text-secondaryHover
}

.red {
    @apply text-primary hover:text-primaryHover
}

.white {
    @apply text-white;
}

.grey {
    @apply text-grey hover:text-secondary;
}