.swiper-button-next.swiper-button-next {
  @apply right-[5%] after:content-['next'] md:right-[25%];
}
.swiper-button-prev.swiper-button-prev {
  @apply left-[5%] after:content-['prev'] md:left-[25%];
}

.swiper-button-next.swiper-button-next,
.swiper-button-prev.swiper-button-prev {
  @apply bottom-0 top-auto h-[26px] w-[26px] rounded-full  bg-grey
    text-white after:text-[6px];
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  @apply bottom-[8px] left-1/2 flex w-[60%] -translate-x-1/2 transform items-center justify-center;
}

.swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  @apply bg-secondary;
}

.swiper-wrapper.swiper-wrapper {
  @apply pb-[3.666rem];
}

.v-swiper-academy .swiper {
  @apply h-full max-h-[26.888rem] pr-[2rem] lg:max-h-[19.111rem];
}

.v-swiper-academy .swiper-wrapper.swiper-wrapper {
  @apply h-full;
}

.v-swiper-academy .swiper-slide {
  @apply mb-xs h-full;
}

.v-swiper-academy .swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  @apply right-0;
}

.webinar-slider.webinar-slider .swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  @apply bg-primary;
}

.v-swiper-fullPage .swiper {
  @apply h-screen max-h-screen;
}

.v-swiper-fullPage .swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  @apply right-[1%] left-auto  hidden max-w-[fit-content] lg:block;
}

.v-swiper-fullPage .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply my-md bg-[#666] first:mt-0 last:mb-0;
}

.v-swiper-fullPage .swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  @apply bg-secondary !important;
}

.sponsorsWrapper .swiper-wrapper.swiper-wrapper {
  @apply pb-0 !important;
}

.sponsorsWrapper .swiper-button-next.swiper-button-next {
  @apply hidden;
}
.sponsorsWrapper .swiper-button-prev.swiper-button-prev {
  @apply hidden;
}

.ModalSlider .swiper-wrapper.swiper-wrapper {
  @apply text-center !important;
}

.thematix-thumbs .swiper-wrapper {
  @apply p-0;
}
.thematix-thumbs .swiper {
  @apply my-[-0.5rem] overflow-visible py-[1.5rem] lg:overflow-hidden;
}

.trade-thematix-slide .swiper {
  @apply xl:overflow-visible;
}
.trade-thematix-slide .swiper-slide {
  @apply h-auto xl:flex xl:min-h-[22em] xl:items-center;
}

.financialDictionarySlide .swiper-slide {
  @apply py-md text-center;
}

.financialDictionarySlide .swiper-wrapper.swiper-wrapper {
  @apply pb-0 !important;
}

.previousIpoSlider .swiper-slide {
  @apply h-auto;
}

.reviewSlider .swiper-slide {
  @apply md:max-w-[31%];
}

.reviewSlider .swiper-slide-active .shadowbox {
  box-shadow: 11px 14px 40px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease-in-out;
}

.reviewSlider .swiper {
  @apply pt-md;
}

.etfsSlider .swiper {
  @apply -mx-md;
}

.etfsSlider .swiper-slide {
  @apply flex flex-col items-center pt-lg transition-all duration-300 ease-in-out;
}

.etfsSlider .swiper-slide-active {
  @apply pt-lg transition-all duration-300 ease-in-out;
}
.etfsSlider .swiper-slide-active {
  transform: scale(1.3) !important;
}

.homepage-reviews-slider .swiper-wrapper.swiper-wrapper {
  @apply pb-0 lg:overflow-hidden;
}

.awardsSlider .swiper-wrapper.swiper-wrapper {
  @apply pb-0;
}
.awardsSlider .swiper-slide {
  @apply ltr:ml-[5px] rtl:mr-[5px] !important;
}

.homepage-reviews-slider .swiper {
  @apply overflow-visible;
}

.homepage-review-slider .swiper-horizontal > .swiper-pagination-bullets,
.homepage-review-slider .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -25%;
}

.homepage-review-slider .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  @apply h-[10px] w-[10px] border;
}

.homepage-review-slider .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.homepage-review-slider .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
  @apply border-0 bg-[#A2A2A1];
}
.regulator-slider .swiper-slide {
  @apply h-auto;
}

.academySlider .swiper-creative .swiper-slide {
  @apply overflow-visible;
}

.academySlider .swiper-button-next.swiper-button-next {
  @apply hidden;
}
.academySlider .swiper-button-prev.swiper-button-prev {
  @apply hidden;
}

.academySlider .swiper-horizontal > .swiper-pagination-bullets,
.academySlider .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-horizontal,
.academySlider .swiper-pagination-custom,
.academySlider .swiper-pagination-fraction {
  @apply bottom-[5%] lg:hidden;
}

.v-swiper-press-homepage .swiper-slide {
  @apply h-full;
}

.v-swiper-press-homepage .swiper {
  @apply h-full max-h-[31.5rem];
}

.home-thematix-swiper .swiper {
  @apply h-full max-h-[30vh] overflow-visible tall:max-h-[15rem];
}
.home-thematix-swiper .swiper-slide {
  @apply flex items-center;
}
.home-thematix-swiper .swiper-slide > div {
  transform-origin: 0% 50%;
}
.home-thematix-swiper .swiper-slide-active {
  z-index: 1;
}

.home-thematix-swiper .swiper .swiper-button-next {
  right: 0;
  left: auto;
  position: absolute;
}

.v-swiper-press-homepage .swiper a,
.testimonialSlider .swiper a,
.heroInsturmentsSlider .swiper a,
.etfs-slider .swiper a,
.press-slide .swiper a,
.academy-lessons-slider .swiper a {
  @apply absolute;
}

.academy-lessons-slider .swiper a {
  @apply h-full w-full top-0 left-0 z-10;
}

.v-swiper-press-homepage.v-swiper-press-homepage
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.v-swiper-press-homepage.v-swiper-press-homepage
  .swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  @apply mx-auto h-[13px] w-[13px] border-[0.5px] border-[#A9A9A9] bg-transparent opacity-100 even:my-[1.3rem];
}

.v-swiper-press-homepage.v-swiper-press-homepage
  .swiper-vertical
  > .swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  @apply h-[17px] w-[17px] bg-[#A2A2A1];
}

.testimonialSlider .swiper {
  @apply overflow-visible;
}

.testimonialSlider .swiper-slide {
  @apply h-auto;
}

.thematix-mobile-slide .swiper {
  @apply overflow-visible;
}
.thematix-mobile-slide .next-image-wrapper * {
  overflow: visible !important;
}

.thematix-mobile-slide .swiper-slide h2,
.thematix-mobile-slide .swiper-slide img {
  @apply scale-100 transition-transform delay-500 duration-300;
}

.thematix-mobile-slide .swiper-slide.swiper-slide-active h2,
.thematix-mobile-slide .swiper-slide.swiper-slide-active img {
  @apply scale-125;
}
@media (min-width: 768px) {
  .thematix-mobile-slide .swiper-slide img {
    @apply origin-right;
  }
  .thematix-mobile-slide .swiper-slide h2 {
    @apply origin-left;
  }
}
.v-slider-invest-words .swiper {
  @apply h-full max-h-[3.22rem];
}

.v-slider-invest-words .swiper-slide {
  @apply w-fit max-w-full;
}

.heroInsturmentsSlider .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.heroInsturmentsSlider .swiper-wrapper.swiper-wrapper {
  @apply pb-0;
}
.licence-swiper-homepage .swiper {
  padding-bottom: 1rem;
  margin-bottom: -1rem;
}
.licence-swiper-homepage .swiper-wrapper.swiper-wrapper {
  @apply pb-[5rem] lg:pb-0;
}

.etfs-slider .swiper-wrapper.swiper-wrapper {
  @apply items-center pt-[3.666rem] pb-0;
}

.homepage-video-slide-mobile {
  direction: ltr !important;
}

.homepage-video-slide-mobile .swiper-wrapper.swiper-wrapper {
  @apply pb-0;
}

.homepage-video-slide-mobile-thumbs .swiper {
  @apply h-full px-md;
}

.homepage-video-slide-mobile-thumbs .swiper-wrapper.swiper-wrapper {
  @apply pb-0;
}

.homepage-video-slide-mobile-thumbs .swiper-slide {
  @apply mb-[0.75rem] h-fit last:mb-0 !important;
}

.instrument-slide .swiper-wrapper.swiper-wrapper {
  @apply pb-0;
}

.instrument-slide .swiper-button-disabled {
  @apply pointer-events-none text-lightGrey;
}
.academy-lessons-slider .swiper-button-disabled {
  @apply pointer-events-none opacity-10;
}
.global-markets-swiper .swiper-slide,
.instrument-slide .swiper-slide {
  @apply h-auto;
}
.global-markets-swiper .swiper {
  @apply overflow-visible lg:overflow-hidden;
}

.global-markets-swiper .swiper-wrapper.swiper-wrapper {
  @apply pb-[0.5rem]
}

.press-image img {
  width: auto !important;
  max-width: none !important;
  min-width: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  @apply z-[11] !important;
}

.v-swiper-press {
  @apply -mb-[2rem];
}
.v-swiper-press .swiper {
  @apply -mx-[2rem]  h-[35rem] px-[2rem];
}

.v-swiper-press .swiper-wrapper.swiper-wrapper {
  @apply h-full;
}

.v-swiper-press .swiper-slide {
  @apply h-full pb-[2rem];
}
.v-swiper-press .swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  @apply top-1/2 -mt-[2rem] translate-x-0 -translate-y-1/2 ltr:left-0 ltr:right-auto rtl:right-0 rtl:left-auto;
}
.v-swiper-press .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.v-swiper-press .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply my-sm rounded-[6px] border border-primary bg-transparent opacity-50  transition-all duration-500 ease-in-out;
}

.v-swiper-press
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply h-[3rem]  bg-primary opacity-100;
}

.swiper-publishers .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-publishers .swiper > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply h-[8px] w-[8px] rounded-[6px] border border-primary  bg-transparent opacity-50 transition-all duration-500 ease-in-out;
}
.swiper-publishers .swiper > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply mx-xs w-[3rem]  bg-primary opacity-100;
}

.academy-lessons-slider .swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  @apply bg-[#D9D9D9] !important;
}

.academy-scopes-slider .swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  @apply bg-primary !important;
}

.academy-scopes-slider .swiper {
  @apply h-full cursor-pointer px-md lg:px-lg !important;
}

.academy-lessons-slider .swiper-pagination {
  @apply bottom-[10%] !important;
}
