@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px) scale(0.75, 0.75);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  4% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  6% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  7% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  8% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  9% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  10% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  20% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  25% {
    transform: translateY(0) scale(1, 1);
  }
  60% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes progressBarHomepage {
  0% {
    background: conic-gradient(#ff4848 0deg, rgba(255, 255, 255, 0) 0deg);
  }
  100% {
    background: conic-gradient(#ff4848 360deg, rgba(255, 255, 255, 0) 0deg);
  }
}

@keyframes phoneGlow {
  0% {
    filter: none;
  }
  15% {
    filter: none;
  }
  20% {
    filter: sepia(1);
  }

  70% {
    filter: sepia(1);
  }
  75% {
    filter: hue-rotate(45deg);
  }

  95% {
    filter: hue-rotate(45deg);
  }
  100% {
    filter: none;
  }
}

.glowing-phone {
  animation: phoneGlow 10s ease infinite;
}

.animate-hide {
  animation: animateHide 2s ease infinite;
}

@keyframes animateHide {
  0% {
    opacity: 0;
    top: -4px;
  }

  50% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
