.containerAuthor p {
  @apply text-sm;
}
.CloseButton {
  position: absolute;
  right: 0;
  bottom: 100%;
  padding-bottom: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}

.responseModal {
  padding: 4em 3em;
  margin: 0;
}
