

.CloseButtonBody {
  position: absolute;
  right: 0;
  bottom: 100%;
  padding-bottom: 0.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}
.CloseText {
  display: block;
  margin: 0 0.5rem;
  font-size: 0.8rem;
}
.CloseButton {
  padding: 0;
  position:relative;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  line-height: 1.25rem;
  justify-content: center;
  line-height: unset;
  display: inline-block;
  text-align: center;
}
.IconCross {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    font-size: 0.75rem;
    line-height: 1rem;
}

.IconCross svg {
  filter: brightness(0) invert(1);
}