/* Desktop Header  */
body:not(.show-sticky) .navbar.sticky-navbar .main-menu ul ul {
  @apply hidden;
}
.navbar {
  @apply absolute left-0  z-10 flex  w-full  flex-col;
}
.navbar.red-bg .register-button span {
  @apply text-primary !important;
}

.navbar.red-bg .header-licence-trigger:hover,
.navbar.red-bg .header-licence-trigger button:hover,
.navbar.red-bg .link-wrapper:hover a,
.navbar.red-bg button:hover,
.navbar.red-bg div > ul > li:hover > a {
  @apply 2xl:text-dark;
}
.navbar .link-wrapper,
.navbar .link-wrapper a {
  @apply transition-none duration-[0s] hover:text-secondaryHover;
}

.header-licence-trigger {
  @apply cursor-pointer text-inherit hover:text-secondaryHover;
}
.header-language-selector button,
.header-licence-trigger button {
  @apply text-inherit transition-none hover:opacity-100 hover:transition-all;
}

.navbar .parent-arrow {
  @apply flex origin-center rotate-0 items-center justify-center transition-transform duration-200;
}
.navbar li:hover .parent-arrow {
  @apply rotate-180;
}
.show-sticky .navbar {
  @apply fixed -top-[4.8888em] translate-y-[4.8888em] bg-[#111114] text-white transition-transform duration-500 ease-in-out !important;
}

.navbar.red-bg .product-tabs a:hover span,
.navbar.red-bg .product-tabs a span {
  color: white;
}
.navbar.red-bg .product-tabs a span:after {
  background: white;
  box-shadow: 0px 3.19654px 7.45858px #fff;
}
.show-sticky .navbar .product-tabs {
  box-shadow: inset 0 0 0 1px #fff;
}
.navbar .register-button button {
  @apply transition-shadow;
}

/* Mobile Menu */

.show-sticky .navbar {
  @apply shadow-md;
}

.hamburger {
  @apply relative -top-[1px] flex h-[1.75em] w-[1.75em] transform-none cursor-pointer overflow-hidden transition-all duration-200 ease-in-out;
}
.mobile-open .hamburger {
  @apply top-[1px] -rotate-180 delay-500;
}
.hamburger span {
  @apply absolute left-0 top-1/2 h-[0.2em]  w-full transform-none rounded-[10px] opacity-100;
}
.hamburger span:nth-child(1) {
  transition: opacity 0.2s ease-in-out 0.2s;
}
.hamburger span:nth-child(2) {
  top: calc(50% - 9px);
  transition: transform 0.2s ease-in-out, width 0.2s ease-in-out 0.4s, top 0.2s ease-in-out 0.2s;
  width: 50%;
  margin-left: auto;
  right: 0;
}
.hamburger span:nth-child(3) {
  top: calc(50% + 9px);
  width: 50%;
  transition: transform 0.2s ease-in-out, top 0.2s ease-in-out 0.2s, width 0.2s ease-in-out 0.4s;
}
.mobile-open .hamburger span:nth-child(1) {
  opacity: 0;
  transition: transform 0.2s ease-in-out 0.4s, opacity 0.2s ease-in-out 0.2s;
}
.mobile-open .hamburger span:nth-child(2) {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.2s ease-in-out 0.4s, top 0.2s ease-in-out 0.2s, width 0.2s ease-in-out;
  width: 100%;
}
.mobile-open .hamburger span:nth-child(3) {
  width: 100%;
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out 0.4s, top 0.2s ease-in-out 0.2s, width 0.2s ease-in-out;
}

.mobile-open {
  @apply overflow-hidden;
}
.mobile-header-wrapper {
  @apply fixed left-full top-0 z-[999999] block h-full w-full  overflow-hidden bg-[rgba(24,22,22,0.95)] text-white transition-[left] duration-500  ease-in-out lg:hidden;
}
.mobile-open .mobile-header-wrapper {
  @apply left-0 shadow-[0_5px_10px_1px_rgba(0,0,0,0.05)];
}

.mobile-header {
  @apply h-full overflow-y-auto;
}

main,
footer,
.mobile-header-wrapper:before {
  @apply relative left-0 transition-[left] duration-500 ease-in-out;
}

.mobile-header-wrapper:before {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  opacity: 0;
  visibility: hidden;
}

.mobile-open header {
  box-shadow: none;
}

.mobile-header h5 {
  @apply relative m-0 flex items-center justify-center border-b border-b-borderGrey py-[2em] px-lg text-center leading-[1.8em];
}
.mobile-header h5 > div {
  @apply absolute top-1/2 left-[0.6rem] -translate-y-1/2;
}
.mobile-header h5 span {
  @apply cursor-pointer text-[2.3rem] uppercase ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold;
}

.header-icons path {
  @apply stroke-inherit;
}
.red-bg .hamburger span,
.show-sticky .hamburger span,
.mobile-header .hamburger span {
  @apply bg-white;
}

.red-bg .soon-tag {
  @apply bg-white text-primary;
}

header .wrong-licence-bar,
header .sticky-risk-warning {
  @apply hidden;
}

.show-sticky header .wrong-licence-bar,
.show-sticky header .sticky-risk-warning {
  @apply block;
}
