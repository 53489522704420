blockquote.article-box {
  background: #f8f6f6;
  border: none;
  font-style: normal;
  background-position: center;
  background-size: cover;
  @apply my-lg -mx-md px-md py-lg  sm:mx-0 sm:rounded-lg lg:p-xl;
}
main blockquote.article-box:not(.article-box-steps) > * {
  @apply first:mt-0 last:mb-0;
}
/* blockquote.article-box > * {
  @apply first:mt-0 last:mb-0;
} */
blockquote.article-box.article-box-center {
  @apply py-lg px-[20%] sm:px-[15%] lg:py-xl;
  background-image: url("https://cdn.capex.com/uploads/article_center_box_bg_142da4b222/article_center_box_bg_142da4b222.png");
  text-align: center;
}

blockquote.article-box.article-box-left {
  background-image: url("https://cdn.capex.com/uploads/article_left_box_bg_40e0137d2d/article_left_box_bg_40e0137d2d.png");
  @apply pr-[20%] sm:pr-[15%];
}
blockquote.article-box.article-box-right {
  background-image: url("https://cdn.capex.com/uploads/article_right_box_bg_02dd606738/article_right_box_bg_02dd606738.png");
  @apply pl-[20%] sm:pl-[15%];
}
blockquote.article-box.article-box-right * {
  text-align: right;
}
blockquote.article-box.article-box-steps {
  background-image: url("https://cdn.capex.com/uploads/article_steps_bg_73e6061889/article_steps_bg_73e6061889.png");

  @apply p-lg;
}

.article-box .table,
.article-box tbody,
.article-box table {
  width: 100% !important;
  display: block !important;
  margin: 0 !important;
  text-align: center;
}

.article-box .table tr {
  width: 100%;
  margin: auto;
  flex-grow: 1;
  flex-basis: 0;
  @apply flex flex-col sm:flex-row;
}
.article-box .table tr td,
.article-box .table tr th {
  flex-basis: 0;
  flex-grow: 1;
  @apply border-none lg:p-md;
}
.article-box .ck-table-column-resizer {
  display: none !important;
}

.article-box h1,
.article-box h2,
.article-box h3,
.article-box h4,
.article-box h5,
.article-box h6 {
  @apply font-semibold;
}

@media (max-width: 639px) {
  blockquote.article-box {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }
  blockquote.article-box.article-box-center {
    background-image: url("https://cdn.capex.com/uploads/article_center_box_bg_mobile_f9fcc463c2/article_center_box_bg_mobile_f9fcc463c2.png");
  }

  blockquote.article-box.article-box-left {
    background-image: url("https://cdn.capex.com/uploads/article_left_box_bg_mobile_2bc3b4aa8e/article_left_box_bg_mobile_2bc3b4aa8e.png");
  }
  blockquote.article-box.article-box-right {
    background-image: url("https://cdn.capex.com/uploads/article_right_box_bg_mobile_163698d22f/article_right_box_bg_mobile_163698d22f.png");
  }
  blockquote.article-box.article-box-steps {
    background-image: url("https://cdn.capex.com/uploads/article_steps_bg_mobile_95e07e833d/article_steps_bg_mobile_95e07e833d.png");
  }
}
