.btn {
  @apply inline-flex  cursor-pointer items-center text-center align-middle   font-medium transition-all duration-300 ease-in-out;
}
.btn-primary p,
.btn-green p,
.btn-secondary p,
.btn-purple p,
.btn-transparent-purple p {
  @apply text-white;
}

.btn-white-transparent p,
.btn-white p,
.btn-white-borderless p {
  @apply text-secondary;
}

.btn-white-purple p,
.btn-white-darkblue p {
  @apply text-dark;
}

.btn-primary {
  @apply bg-primary   text-white hover:bg-primaryHover;
}
.btn-green {
  @apply bg-greenCandle   text-white hover:bg-greenCandleHover;
}
.btn-secondary {
  @apply bg-secondary  text-white hover:bg-secondaryHover;
}

.btn-white-transparent {
  @apply border border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white;
}

.btn-white {
  @apply border border-secondary bg-white text-secondary hover:bg-secondary hover:text-white;
}

.btn-white-borderless {
  @apply bg-white text-secondary hover:bg-[#f2f2f2];
}

.btn-purple {
  @apply bg-[#853FA6] text-white active:bg-[#AD7AC5];
}

.btn-white-darkblue {
  @apply border border-black bg-white text-dark hover:bg-[#00247d] hover:text-white;
}

.btn-white-purple {
  @apply border border-black bg-white text-dark hover:bg-[#853FA6] hover:text-white hover:border-[#853FA6];
}

.btn-transparent-purple {
  @apply border border-white border-opacity-[30%] bg-transparent text-white hover:bg-[#853FA6] hover:text-white;
}

.btn-link {
  @apply m-0 bg-transparent p-0 text-secondary shadow-none hover:bg-transparent hover:opacity-70;
}

.btn-disabled {
  @apply cursor-not-allowed  bg-lightGrey text-grey  opacity-50  hover:bg-lightGrey;
}

.btn-size-lg {
  @apply rounded-md px-[1.7em] py-[0.7em] shadow-sm;
}

.btn-size-sm {
  @apply rounded-md px-[1.7em] py-[0.4em] text-[0.888rem]  shadow-sm;
}

.btn-arrow-right {
  @apply items-center after:relative after:top-[0.05em] after:z-[1] after:inline-block after:h-0 after:w-0 after:border-t-[0.28em] after:border-b-[0.28em] after:border-t-transparent after:border-b-transparent after:align-middle after:opacity-0 after:transition-[margin,opacity] after:duration-700 after:ease-InOutBack hover:after:opacity-100 ltr:text-left ltr:after:-ml-[0.35em] ltr:after:border-l-[0.35em] ltr:after:border-l-current ltr:hover:after:ml-[1.5em] rtl:text-right rtl:after:-mr-[0.35em] rtl:after:border-r-[0.35em] rtl:after:border-r-current rtl:hover:after:mr-[1.5em];
}

.btn-arrow-left {
  @apply items-center before:relative before:top-[0.05em] before:z-[1] before:inline-block before:h-0 before:w-0 before:border-t-[0.28em] before:border-b-[0.28em] before:border-t-transparent before:border-b-transparent before:align-middle before:opacity-0 before:transition-[margin,opacity] before:duration-700 before:ease-InOutBack hover:before:opacity-100 ltr:text-left ltr:before:-mr-[0.35em] ltr:before:border-l-[0.35em] ltr:before:border-l-current ltr:hover:before:mr-[1.5em] rtl:text-right rtl:before:-ml-[0.35em] rtl:before:border-r-[0.35em] rtl:before:border-r-current rtl:hover:before:ml-[1.5em];
}

.btn-arrow-none {
  @apply block;
}

.btn-arrow-down {
  @apply items-center after:relative after:top-[0.05em] after:inline-block after:h-0 after:w-0 after:rotate-90 after:border-t-[0.28em] after:border-b-[0.28em] after:border-l-[0.35em] after:border-t-transparent after:border-b-transparent after:border-l-current after:align-middle ltr:text-left ltr:after:ml-[1em] rtl:text-right rtl:after:mr-[1em];
}
.btn-arrow-labeled-right {
  @apply items-center after:relative after:top-[0.05em] after:inline-block after:h-0 after:w-0 after:border-t-[0.28em] after:border-b-[0.28em] after:border-t-transparent after:border-b-transparent after:align-middle ltr:text-left ltr:after:ml-[3em] ltr:after:border-l-[0.35em] ltr:after:border-l-current rtl:text-right rtl:after:mr-[3em] rtl:after:border-r-[0.35em] rtl:after:border-r-current;
}

.btn-arrow-labeled-left {
  @apply items-center before:relative before:top-[0.05em] before:inline-block before:h-0 before:w-0 before:border-t-[0.28em] before:border-b-[0.28em] before:border-t-transparent before:border-b-transparent before:align-middle ltr:text-left ltr:before:mr-[3em] ltr:before:border-l-[0.35em] ltr:before:border-l-current rtl:text-right rtl:before:ml-[3em] rtl:before:border-r-[0.35em] rtl:before:border-r-current;
}

.btn-arrow-labeled-down {
  @apply items-center after:relative after:top-[0.05em] after:inline-block after:h-0 after:w-0 after:rotate-90 after:border-t-[0.28em] after:border-b-[0.28em] after:border-t-transparent after:border-b-transparent after:align-middle ltr:text-left ltr:after:ml-[3em] ltr:after:border-l-[0.35em] ltr:after:border-l-current rtl:text-right rtl:after:mr-[3em] rtl:after:border-r-[0.35em] rtl:after:border-r-current;
}

.btn-primary-subLabel {
  @apply text-white;
}
.btn-secondary-subLabel {
  @apply text-white;
}

.btn-disabled-subLabel {
  @apply text-grey;
}

.fullWidth {
  @apply flex w-full justify-center text-center;
}
button .btnLink,
button .btnLink a {
  @apply absolute top-0 left-0 z-10 h-full w-full cursor-pointer;
}
form button.btn-crypto,
.btn-crypto {
  @apply overflow-hidden bg-cryptoYellow text-dark  hover:bg-cryptoYellow;
}
.btn-crypto:hover {
  @apply text-white;
}
.btn-crypto * {
  @apply relative z-10;
}
.bgHover {
  @apply hidden;
}
.btn-crypto .bgHover {
  content: "";
  position: absolute;
  z-index: 0;
  height: 225%;
  width: 149%;
  border-radius: 50%;
  bottom: 100%;
  left: 100%;
  transition: all 0.5s;
  opacity: 0;
  @apply block bg-dark;
}
.btn-crypto:hover .bgHover {
  bottom: -30px;
  left: -30px;
  opacity: 1;
}
