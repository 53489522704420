.containerAuthor p {
  @apply text-sm;
}
.CloseButton {
  position: absolute;
  right: 0;
  bottom: 100%;
  padding-bottom: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}

.responseModal {
  @apply m-0 py-[3rem] px-md lg:py-[4rem] lg:px-[3rem];
}

.responseModal h3 {
  @apply mt-[2rem] mb-0 font-semibold text-grey;
}
.responseModal p {
  @apply text-grey ltr:font-gilroy rtl:font-dubai lg:text-lg;
}
.responseModal h4 {
  @apply mb-0 font-semibold;
}
